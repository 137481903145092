<template>
  <div>
    <el-button type="primary" @click="dialogFormVisible = true">{{ $t(`Career['applyNow']`) }}</el-button>
      <el-dialog :title="$t(`Career['CvOrResume']`)" :visible.sync="dialogFormVisible" custom-class="cv-dialog" style="margin-top: 20vh">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="" prop="firstName">
              <el-input v-model="ruleForm.firstName" :placeholder="$t(`Career['firstName']`)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="" prop="lastName">
              <el-input v-model="ruleForm.lastName" :placeholder="$t(`Career['lastName']`)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="" prop="email">
              <el-input v-model="ruleForm.email" :placeholder="$t(`Career['Email']`)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="" prop="mobileNumber">
              <el-input v-model="ruleForm.mobileNumber" :placeholder="$t(`Career['MobileNumber']`)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
                <el-row :gutter="24" class="upload-cv">
        <el-col :span="24">
          <h1 style="font-size: 17px; font-weight: 500; margin-bottom: 10px;">{{ $t(`Career['attachment']`) }}</h1>
          <el-form-item label="" prop="fileList">
          <el-upload
            class="upload-demo"
            action=""
            :http-request="uploadImage"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="ruleForm.fileUrl"
            >
            <el-button size="small" type="">{{ $t(`Career['clickToUpload']`) }}</el-button>
          </el-upload>
        </el-form-item>
          <div class="el-upload__tip" style="font-size:16px; color: #8C8C8C">{{ $t(`Career['pdfFilesApply']`) }}</div>
        </el-col>
        <el-col :span="12"></el-col>
      </el-row>
      <span slot="" class="dialog-footer">
        <el-button  @click="submitForm('ruleForm')">{{ $t(`Career['sendCV']`) }}</el-button>
      </span>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { postSendCv } from '../../api/sendCv'
import axios from 'axios'

export default {
  data() {
    return {
      dialogFormVisible: false,
      ruleForm: {
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        mobileNumber: '',
        fileUrl: ''
      },
      formLabelWidth: '120px',
      rules: {
        firstName: [
          { required: true, message: this.$t('Career.input_activity'), trigger: 'blur' },
          { message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        lastName: [
          { required: true, message: this.$t('Career.input_zone'), trigger: 'change' }
        ],
        email: [
          { required: true, message: this.$t('Career.input_email'), trigger: 'change' }
        ],
        mobileNumber: [
          { required: true, message: this.$t('Career.input_phoneNumber'), trigger: 'change' }
        ],
        fileUrl: [
          { required: true, message: this.$t('Career.input_resource'), trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    'form.fileList'(newVal, oldVal) {
      console.log('Hello', newVal)
    }
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log('sssssss', file)
    },
    handleExceed(files, fileList) {
      console.log('File', files)
      this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`)
    },
    beforeRemove(file) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`)
    },
    uploadImage(data) {
      this.upload(process.env.VUE_APP_BASE_API + '/dev-api/file/webUpload', data.file).then(res => {
        this.ruleForm.fileUrl = res.data
      })
    },
    upload(api, file) {
      var data = new FormData()
      data.append('file', file)
      return axios.post(api, data)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          postSendCv(this.ruleForm)
            .then(async response => {

            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.el-button {
    padding: 15px 20px 12px;
  }
::v-deep {
  .el-dialog__wrapper {
    .el-dialog.cv-dialog {
      padding: 10px;
      border-radius: 20px;
      @media screen and (max-width: 992px) {
        width: 95%;
      }
      .el-dialog__title {
          line-height: 24px;
          font-size: 17px;
          color: #000;
      }
      .el-dialog__headerbtn .el-dialog__close {
          color: #000;
          font-size: 22px;
      }
      .dialog-footer {
        width: 100%;
        justify-content: flex-end;
        display: flex;
      }
    }
    li.el-upload-list__item {
      padding: 10px;
      border: 1px solid #ddd;
      .el-upload-list__item-status-label {
        top: 10px;
      }
      .el-icon-close {
          top: 15px;
        }
    }
  }
}
</style>
